<template>
  <b-input v-model="nic" focused autofocus
           :placeholder="placeholder"
           @keydown.enter="searchStudent"
           @keydown.tab="searchStudent"
           :size="size"
  ></b-input>
</template>

<script>
import { QR2NIC } from '@/plugins/functions'
export default {
  name: "nic_field",
  props: [
      'placeholder', 'size', 'nic'
  ],
  methods: {
    QR2NIC,
    async searchStudent(event) {
      if(event.key === 'Control' || event.key === 'j') {
        event.preventDefault()
      }

      clearTimeout(this.timeout);

      // Make a new timeout set to go off in 800ms
      this.timeout = await setTimeout(() => {
        console.log('nic_before_pararing', this.nic);
        if(this.nic) {
          //===============================================
          // Disabled due to introduction of student cards
          // as the needs to remove unwanted leading text
          // from Barcode scanner is no longer required
          //===============================================
          if(this.nic.length > 9){
            // console.log('full_nic', this.nic)
           let prepared_nic = this.QR2NIC(this.nic);//.toString()
            // let prepared_nic = this.nic
            console.log('prepared_nic', prepared_nic)
            this.$emit('updateNic', prepared_nic)
           //  this.$emit('updateNic', this.nic)
           //  this.nic = ''
          }
        }
      }, 500);

    },
  },
}
</script>

<style scoped>

</style>
