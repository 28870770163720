<template>
  <div>
    <Breadcrumbs title="Home" />
    <div class="container-fluid">

      <div class="card">
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-6 col-lg-7">
              <b-form-group label="Student NIC *">
                <nic_field size="lg" placeholder="Search student by NIC" :nic="nic" v-on:updateNic="assignNIC" />
              </b-form-group>
            </div>
            <div class="col-md-2 col-lg-3">
              <b-form-group label="Institute *">
                <b-form-select @change="searchStudent" class="form-control border" size="lg" v-model="institute_id" :options="user.institutes" value-field="id" text-field="name" required>
                  <template #first>
                    <b-form-select-option :value="null" selected="selected">-- All Institutes --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-2 col-lg-2 col-xl-2">
              <b-button class="btn btn-block btn-square btn-primary btn-lg m-t-30" @click="clearForm" :disabled="nic===null">CLEAR</b-button>
            </div>

          </div>

          <div class="form-row" v-if="student != null">
            <div class="col-lg-9 col-xl-10">
              <b-form-group>
                <b-form-select class="btn-square border" size="lg" v-model="assign_class.class_detail_id" :options="class_selections" value-field="id" text-field="text">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Select a class --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-lg-3 col-xl-2">
              <b-button @click.prevent="submitAssignClass" :disabled="!assign_class.class_detail_id" class="btn btn-block btn-square btn-primary btn-lg">Assign</b-button>
            </div>
          </div>

        </div>
      </div>

      <div v-if="student != null" class="row m-t-20">
        <div class="col-lg-9 pull-right">
          <StudentMarker v-if="institute_id" :student_id="student.id" :institute_id="institute_id" :institutes="user.institutes" />

          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Payment History</h5>
            </div>
            <div class="card-body">

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="payment_log_table"
                    show-empty
                    :items="payment_log"
                    :fields="payment_log_fields"
                    current-page="1"
                    per-page="10"
                >
                  <template #cell(created_at)="row"><span>{{ moment(row.item.created_at).format('lll') }}</span></template>
                  <template #cell(payment_month)="row">{{ moment(row.item.payment_month).format('YYYY-MM') }}</template>
                  <template #cell(class_fee)="row">Rs. {{ row.item.class_fee }}</template>
                  <template #cell(cashier)="row">{{ row.item.creator.first_name }}</template>
                  <template #cell(amount)="row">
                    <template v-if="row.item.free_card_type === 2">
                      Rs. {{ row.item.amount }} <span class="badge badge-warning">H/C</span>
                    </template>
                    <template v-else-if="row.item.free_card_type === 1"><span class="badge badge-danger">Free Card</span></template>
                    <template v-else>Rs. {{ row.item.amount }}</template>
                  </template>
                  <template #cell(status)="row">
                  <template v-if="row.item.status == 2">
                       <span class="badge badge-warning">Cancelled</span>
                    </template>
                    <template v-else> <span class="badge badge-success">Paid</span></template>
                  
                  </template>
                </b-table>
              </div>


            </div>
          </div>

        </div>
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Student Details</h5>
            </div>
            <table class="table table-responsive table-sm">
              <tr v-if="user.roles.includes('developer')">
                <td class="text-left text-md-right">{{student.id}}</td>
                <td><strong>User ID</strong></td>
              </tr>
              <tr>
                <td class="text-left text-md-right">{{student.nic}}</td>
                <td><strong>NIC</strong></td>
              </tr>
              <tr>
                <td style="width: 100%;">
                  <b-row>
                    <b-col cols="12" class="text-left text-md-right"><h5>{{student.phone_mobile || "N/A"}}</h5></b-col>
                    <b-col cols="12">
                      <b-button class="btn btn-square btn-block" size="sm" @click="showEditMobileModal($event)">Edit Mobile</b-button>
                    </b-col>
                  </b-row>
                </td>
                <td><strong>Mobile</strong></td>
              </tr>
              <tr>
                <td class="text-left text-md-right">{{student.username}}</td>
                <td><strong>Username</strong></td>
              </tr>
              <tr>
                <td class="text-left text-md-right">{{student.first_name}}</td>
                <td><strong>First Name</strong></td>
              </tr>
              <tr>
                <td class="text-left text-md-right">{{student.last_name}}</td>
                <td><strong>Last Name</strong></td>
              </tr>
              <tr title="Date Of Birth">
                <td class="text-left text-md-right">{{student.dob}}</td>
                <td><strong>DOB</strong></td>
              </tr>
              <tr>
                <td class="text-left text-md-right">{{student.email || "N/A"}}</td>
                <td><strong>Email</strong></td>
              </tr>
              <tr>
                <td class="text-left text-md-right">{{student.roles.map((role) => { return this.capitalizeFirstLetter(role.name) }).join(', ')}}</td>
                <td><strong>Role(s)</strong></td>
              </tr>
              <tr>
                <td class="text-left text-md-right">{{ new Date(student.created_at).toDateString() }}</td>
                <td><strong>Registered Date</strong></td>
              </tr>
              <tr>
                <td class="text-left text-md-right">
                  <b-row>
                    <b-col cols="10" class="text-left text-md-right">
                      {{ student.address_line_1 }}, {{ student.address_line_2 }}, {{ student.town_id ? student.town.name : '' }}
                    </b-col>
                    <b-col cols="2" class="text-left text-md-right">
                      <b-button class="btn btn-square" size="xs" @click="showEditAddressModal($event)"><span class="fa fa-pencil"></span></b-button>
                    </b-col>
                  </b-row>
                </td>
                <td><strong>Address</strong></td>
              </tr>
            </table>

          </div>


          <div class="card">
            <div class="card-header m-t-20 py-2">
              <h5 class="card-title">Attendance History</h5>
            </div>
            <div class="card-body pt-2">
              <ul  v-for="(log, index) in attendance_log" :key="index"  class="dates mb-4">
                <li class="digits">{{log.name}}</li>
                <li class="digits">
                  {{ moment(log.created_at).tz('Asia/Colombo').format('ll') }} (Seat {{ log.seat_number }})
                </li>
              </ul>
            </div>


          </div>
        </div>
      </div>

    </div>


    <!-- Begin Edit Mobile Modal -->
    <b-modal id="edit_mobile_modal" title="Edit Mobile Number" cancel-variant="default" ok-variant="primary" ok-title="Update" @hide="searchStudent" @ok="submitEditMobilModal">
      <b-form-group label="Mobile Number *" v-if="student">
        <b-form-input v-model="student.phone_mobile" class="btn-square border" required autofocus />
        <b-form-invalid-feedback :state="hasError('phone_mobile')">{{getError('phone_mobile')}}</b-form-invalid-feedback>
      </b-form-group>
    </b-modal>
    <!-- End Edit Mobile Modal -->

    <!-- Begin Edit Address Modal -->
    <b-modal id="edit_address_modal" title="Edit Address" cancel-variant="default" ok-variant="primary" ok-title="Update" @hide="searchStudent" @ok="submitEditAddressModal">

      <b-form-group label="Address Line 1 *" v-if="student">
        <b-form-input v-model="student.address_line_1" class="btn-square border" required autofocus />
        <b-form-invalid-feedback :state="hasError('address_line_1')">{{getError('address_line_1')}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Address Line 2" v-if="student">
        <b-form-input v-model="student.address_line_2" class="btn-square border" required autofocus />
        <b-form-invalid-feedback :state="hasError('address_line_2')">{{getError('address_line_2')}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Town">
        <b-form-select class="btn-square border" v-model="student.town_id" :options="towns" value-field="id" text-field="text" v-if="student">
          <template #first>
            <b-form-select-option :value="null" disabled>-- Select a town --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

    </b-modal>
    <!-- End Edit Address Modal -->

  </div>
</template>

<script>
import { QR2NIC, checkUserPermissions } from '@/plugins/functions'
import StudentMarker from "@/pages/dashboard/StudentMarker";
import nic_field from "@/components/nic_field";
import API from '@/services/api'
import CRUD_API from '@/services/crud'
import { mapGetters } from 'vuex'
export default {
  name: "dashboard",
  components: { StudentMarker, nic_field },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 2)
    minDate.setDate(15)
    // 15th in two months
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 2)
    maxDate.setDate(15)
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      api_base: '/api/backend/pages/classrooms',
      tableFields: [
        // { key: 'id', label: 'ID', sortable: true, },
        { key: 'name', label: 'Name', sortable: true, },
        { key: 'class_fee', label: 'Class Fee', sortable: true, },
        { key: 'teacher.username', label: 'Teacher', sortable: true, },
        // { key: 'status', label: 'Status', sortable: true, },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],


      payment_log_fields: [
        { key: 'created_at', label: 'Paid Date', sortable: false },
        { key: 'institute.name', label: 'Institute', sortable: false },
        { key: 'class_detail.name', label: 'Class', sortable: false },
        { key: 'cashier', label: 'Cashier', sortable: false, class: 'text-right' },
        { key: 'amount', label: 'Paid Amount', sortable: false, class: 'text-right' },
        { key: 'payment_month', label: 'Paid Month', sortable: false, class: 'text-center' },
        { key: 'status', label: 'Status', sortable: false, class: 'text-center' },
      ],

      class_selections: null,
      assign_class: {
        class_detail_id: null
      },

      nic: '',
      is_searching: false,
      institute_id: null,
      student: null,
      institutes: [],
      attendance_log: [],
      payment_log: [],
      errorBag: null,
      towns: []
    }
  },
  created() {
    this.$store.dispatch('auth/getUser')
    this.$store.dispatch('select/fetchRecords', { id: 'subjects' })
    this.$store.dispatch('select/fetchRecords', { id: 'free-card-types' })
    this.$store.dispatch('select/fetchRecords', { id: 'payment-types' })
  },
  mounted() {
    window.eventBus.$on('clear_selected_student', function () {
      this.clearForm()
    })
  },
  computed: mapGetters({
    user: 'auth/user',
    authenticated: 'auth/authenticated',
    free_card_types: 'select/free-card-types',
    payment_types: 'select/payment-types',
    towns: 'select/towns',
  }),
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    },
    nic() {
      this.searchStudent()
    }
  },
  methods: {
    QR2NIC,
    checkUserPermissions,
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },
    assignNIC(value){ this.nic = value },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    clearForm() {
      this.nic = null
      this.student = null
      this.institute_id = null
    },
    loadStudentAvailableClasses() {
      if(this.student && this.institute_id != null) {
        API.select('classes', {
          institute_id: this.institute_id,
          student_id: this.student.id,
          select_mode: 'available'
        }).then((response) => {
          this.class_selections = response.data.data
        }).catch((errors) => {
          console.log(errors)
          this.class_selections = null
        })
      }
    },
    async searchStudent() {
      if(this.nic && !this.is_searching) {
        this.student = null
        this.is_searching = true
        API.student_by_nic('/api/backend', {
          nic: this.nic,
          institute_id: this.institute_id,
          with_relations: ['roles:id,name','institutes:id,name']
        }).then((response) => {
          if(response.data.success) {
            this.student = response.data.data
            if(this.student.institutes.length === 1) {
              this.institute_id = this.student.institutes[0].id
            }
            if (this.institute_id != null) {
              this.loadStudentAvailableClasses()
              this.fetchAttendanceHistory()
              this.fetchPaymentHistory()
            }
          } else {
            this.$toasted.error(response.data.message)
          }
        }).catch((error) => {
          console.log('SYSTEM_ERROR', error)
          this.$toasted.error("System error.")
        }).finally(() => {
          this.is_searching = false
          this.$store.dispatch('select/fetchRecords', { id: 'towns' })
        })
      }
    },
    submitAssignClass() {
      CRUD_API.create('/api/backend/pages/user-assign-class', {
        class_detail_id: this.assign_class.class_detail_id,
        institute_id: this.institute_id,
        student_id: this.student.id
      }).then((response) => {
        this.$toasted.success(response.data.message)
      }).catch((errors) => {
        console.log(errors)
        this.$toasted.success(errors.data.message)
      }).finally(() => {
        this.searchStudent()
      })
    },
    async fetchAttendanceHistory(){
      this.attendance_log = []
      await API.post('/api/backend/get-3m-attendance', {
        institute_id: this.institute_id,
        student_id: this.student.id
      }).then((response) => {
        this.attendance_log = response.data.data
      }).catch((error) => {
        console.log(error)
      })
    },
    async fetchPaymentHistory(){
      this.payment_log = []
      await API.post('/api/backend/get-3m-payments', {
        institute_id: this.institute_id,
        student_id: this.student.id
      }).then((response) => {
        this.payment_log = response.data.data
      }).catch((error) => {
        console.log(error)
      })
    },


    showEditMobileModal(button) {
      this.$root.$emit('bv::show::modal', 'edit_mobile_modal', button)
    },
    async submitEditMobilModal(e) {
      await API.post('/api/backend/update-mobile', {
        user_id: this.student.id,
        phone_mobile: this.student.phone_mobile
      }).then(result => {
        if(result.data.success) {
          this.$toasted.success(result.data.message)
        } else {
          this.errorBag = result.data.data
          this.$toasted.error(result.data.message)
        }
      }).catch(error => {
        e.preventDefault()
        this.errorBag = error.data.data
        this.$toasted.error(error.message)
      }).finally(() => {
        this.stopPropagation()
      })
    },

    showEditAddressModal(button) {
      this.$root.$emit('bv::show::modal', 'edit_address_modal', button)
    },
    async submitEditAddressModal(e) {
      await API.post('/api/backend/update-address', {
        user_id: this.student.id,
        address_line_1: this.student.address_line_1,
        address_line_2: this.student.address_line_2,
        town_id: this.student.town_id
      }).then(result => {
        if(result.data.success) {
          this.$toasted.success(result.data.message)
        } else {
          this.errorBag = result.data.data
          this.$toasted.error(result.data.message)
        }
      }).catch(error => {
        e.preventDefault()
        this.errorBag = error.data.data
        this.$toasted.error(error.message)
      }).finally(() => {
        this.stopPropagation()
      })
    }
  }
}
</script>

<style scoped>

</style>
